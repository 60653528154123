import { CheckIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../util/auth";
import Section from "../Util/Section";

export const plans = [
  {
    id: "free",
    name: "Free",
    price: "0",
    frequency: "/month",
    description: "The essentials to provide your best work for clients.",
    features: [
      "Generate 2k words free trial",
      "Access 7 powerful tools",
      "History search",
    ],
    cta: "Get Started",
    mostPopular: false,
    numWords: 2000,
  },
  {
    id: "saver",
    name: "Saver",
    price: 5,
    frequency: "/month",
    description: "A plan that scales with your rapidly growing business.",
    features: [
      "Generate 75k words",
      "Access 7 powerful tools",
      "History search",
      "Premium content quality output",
      "Longer form content output",
    ],
    cta: "Get Started",
    mostPopular: true,
    numWords: 75000,
  },
  {
    id: "premium",
    name: "Premium",
    price: 25,
    frequency: "/month",
    description: "Dedicated support and infrastructure for your company.",
    features: [
      "Generate unlimited words",
      "Access 7 powerful tools",
      "History Search",
      "Highest quality content quality output",
      "Longer form content output",
      "Early access to newest features",
      "Priority email support",
    ],
    cta: "Get Started",
    mostPopular: false,
    numWords: "Unlimited",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingSection(props) {
  const auth = useAuth();

  return (
    <Section
      bgColor={props.bgColor}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <div className="mx-auto max-w-7xl bg-white px-6 lg:px-8">
        <div className="mx-auto max-w-7xl bg-white px-6 lg:px-8">
          <div className="pb-8">
            <a href="/" aria-label="Home">
              <img className="h-10" src={require("../Images/YipperLogo.png")} />
            </a>
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
            Pricing Plans
          </h2>

          <p className="mt-6 max-w-2xl text-xl text-gray-500">
            Start writing unique and original content 10x faster with the help
            of AI today!
          </p>

          {/* Plans */}
          <div className="mt-12 space-y-12">
            {plans.map((plan, index) => (
              <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm">
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {plan.name}
                  </h3>
                  {plan.mostPopular ? (
                    <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-indigo-500 py-1.5 px-4 text-sm font-semibold text-white">
                      Most popular
                    </p>
                  ) : null}
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-5xl font-bold tracking-tight">
                      ${plan.price}
                    </span>
                    <span className="ml-1 text-xl font-semibold">
                      {plan.frequency}
                    </span>
                  </p>
                  <p className="mt-6 text-gray-500">{plan.description}</p>

                  {/* Feature list */}
                  <ul className="mt-6 space-y-6">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex">
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-indigo-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  href={
                    auth.user
                      ? `/purchase/${plan.id}`
                      : `/auth/signup?next=/purchase/${plan.id}`
                  }
                  className={classNames(
                    plan.mostPopular
                      ? "bg-indigo-500 text-white hover:bg-indigo-600"
                      : "bg-indigo-50 text-indigo-700 hover:bg-indigo-100",
                    "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
                  )}
                >
                  {plan.cta}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
}

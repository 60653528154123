import { useEffect, useState } from "react";
import { apiRequest } from "../../util/util";
import { createItem, updateItem } from "../../util/db";
import { useAuth } from "../../util/auth";
import GenerateButton from "./GenerateButton";

export default function CompanyTaglinesGeneratorMainContent(props) {
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [slogan, setSlogan] = useState("");
  const [sloganId, setSloganId] = useState("");

  const { pastActivityItem } = props;
  useEffect(() => {
    if (pastActivityItem) {
      setCompany(pastActivityItem.recipient);
      setDescription(pastActivityItem.context);
      setSlogan(pastActivityItem.content);
      setSloganId(pastActivityItem.id);
    }
  }, [pastActivityItem]);

  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const genSlogan = await apiRequest("generate-slogan", "POST", {
      company,
      description,
      uid: auth.user.uid,
    });
    setSlogan(genSlogan);
    const ref = await createItem({
      content: genSlogan,
      company,
      description,
      owner: auth.user.uid,
      tool: "slogan",
    });
    setSloganId(ref.id);
  };

  const updateSlogan = async (text) => {
    setSlogan(text);
    updateItem(sloganId, {
      content: text,
      recipient: company,
      context: description,
      owner: auth.user.uid,
      tool: "slogan",
    });
  };

  const handleReset = () => {
    setCompany("");
    setDescription("");
    setSlogan("");
    setSloganId("");
  };

  return (
    <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
      {/* <div className="h-full rounded-lg border-2 border-dashed border-gray-200" />  */}
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Generate Company Mission Statements/Taglines/Slogans
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fill out the following two boxes as best as you can, and we'll
                  take it from there!
                </p>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label
                    htmlFor="company-website"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company Name
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block w-full min-w-0 flex-grow rounded-l-md rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="ex. John’s Furniture"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    What does your business do?
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="DTC ecommerce selling high quality and affordable home goods!"
                      defaultValue={""}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="reset"
                style={{ marginRight: "10px" }}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {slogan ? "New" : "Clear"}
              </button>
              <GenerateButton disabled={!company && !description} />
            </div>
          </div>
        </form>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 pb-4">
                Yipper Generated Content
              </h3>
              <div className="col-span-3">
                <div className="mt-1">
                  <textarea
                    disabled={!slogan}
                    id="email"
                    name="email"
                    rows={14}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Press generate and your content will appear here"
                    value={slogan}
                    defaultValue={""}
                    onChange={(e) => updateSlogan(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

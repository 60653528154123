import React from "react";
import { Link } from "../../util/router";
import LegalTerms from "./LegalTerms";
import LegalPrivacy from "./LegalPrivacy";
import Section from "../Util/Section";

function LegalSection(props) {
  const validSections = {
    "terms-of-service": true,
    "privacy-policy": true,
  };

  const section = validSections[props.section]
    ? props.section
    : "terms-of-service";

  const data = {
    domain: "company.com",
    companyName: "Company",
  };

  return (
    <Section
      bgColor={props.bgColor}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      bgImageRepeat={props.bgImageRepeat}
    >
      <div className="container">
        <nav class="flex items-center border-b border-gray-200 max-w-sm mx-auto mb-12">
          {[
            [
              "Terms of Service",
              "/legal/terms-of-service",
              section === "terms-of-service",
            ],
            [
              "Privacy Policy",
              "/legal/privacy-policy",
              section === "privacy-policy",
            ],
          ].map(([name, path, selected]) => (
            <Link
              to={path}
              class={
                "flex items-center grow justify-center px-3 md:px-4 -mb-px py-4 border-b-2" +
                (selected
                  ? " text-blue-500 border-blue-500"
                  : " border-transparent hover:text-blue-500")
              }
            >
              {name}
            </Link>
          ))}
        </nav>

        {section === "terms-of-service" && <LegalTerms {...data} />}

        {section === "privacy-policy" && <LegalPrivacy {...data} />}
      </div>
    </Section>
  );
}

export default LegalSection;

import React from "react";
import "./../styles/global.css";
import PricingPage from "./pricing";
import AuthPage from "./auth";
import LegalPage from "./legal";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import Nav from "./../Nav.js";
import Dashboard from "./dashboard";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          {/* <Nav /> */}
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/auth/:type" component={AuthPage} />=
            <Route exact path="/legal/:section" component={LegalPage} />
            <Route exact path="/purchase/:plan" component={PurchasePage} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              exact
              path="/firebase-action"
              component={FirebaseActionPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;

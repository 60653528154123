import React, { useEffect, useState } from "react";
import { useAuth } from "../../util/auth";
import { useRouter } from "../../util/router";
import { redirectToBilling } from "../../util/stripe";
import PricingSection from "./PricingSection";
import { useNavigate } from "react-router-dom";

function SettingsBilling(props) {
  const router = useRouter();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user has an active plan then
      // take them to Stripe billing
      redirectToBilling().catch((error) => {
        setLoading(false);
        props.onStatus({
          type: "error",
          message: error.message,
        });
      });
    } else {
      // Otherwise go to pricing so they can
      // purchase a plan
      router.replace("/");
      // for now do nothing
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (auth.user.planIsActive) {
    return <>{loading && <div>Loading...</div>}</>;
  } else {
    return (
      <PricingSection
        size="medium"
        bgColor="bg-white"
        title="Choose the best plan"
        subtitle="Get to know our premium solutions and get your start up off the ground in no time. Inspiring results from the first days."
        strapline="Pricing"
      />
    );
  }
}

export default SettingsBilling;

import React from "react";
import { Path } from "path-parser";
import { useRouter } from "./util/router.js";
import { useAuth } from "./util/auth.js";

const types = [
  { name: "Pricing", page: "/pricing" },
  { name: "Authentication", page: "/auth/:type" },
  // { name: "Settings", page: "/settings/:section", requiresLogin: true },
  { name: "Dashboard", page: "/dashboard", requiresLogin: true },
  { name: "Legal", page: "/legal/:section" },
];

function Nav(props) {
  const auth = useAuth();
  const router = useRouter();

  let currentTypePage;
  types.forEach((type, i) => {
    const pathMatcher = new Path(type.page);
    if (pathMatcher.test(router.pathname)) {
      currentTypePage = type.page;
    }
  });

  return (
    <div
      style={{
        position: "relative",
        padding: "1rem",
        backgroundColor: "white",
        zIndex: 1101,
        boxShadow: "0 2px 5px 0 rgba(0,0,0,0.16)",
        color: "#000",
      }}
    >
      <a href="/" style={{ fontWeight: "bold", marginRight: "1rem" }}>
        Divjoy Library (Tailwind)
      </a>

      <select
        onChange={(event) => router.push(event.target.value)}
        value={currentTypePage}
        style={{ marginLeft: "1rem" }}
      >
        <option value="">Home Page</option>
        {types.map((type, i) => {
          return (
            <option key={i} value={type.page}>
              {type.name} {type.requiresLogin && !auth.user && "(must sign-in)"}
            </option>
          );
        })}
      </select>
      <span style={{ marginLeft: "1rem" }}>
        {auth.user ? (
          <a
            href="/auth/signout"
            onClick={(e) => {
              e.preventDefault();
              auth.signout();
            }}
          >
            Sign out
          </a>
        ) : (
          <a
            href="/auth/signin"
            onClick={(e) => {
              e.preventDefault();
              router.push(`/auth/signin`);
            }}
          >
            Sign in
          </a>
        )}
      </span>
    </div>
  );
}

export default Nav;

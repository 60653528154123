function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GenerateButton = ({ disabled }) => {
  return (
    <button
      type="submit"
      disabled={disabled}
      className={classNames(
        disabled ? "disabled:opacity-50" : "",
        "inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      )}
    >
      Generate
    </button>
  );
};

export default GenerateButton;

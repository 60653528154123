import { useEffect, useState } from "react";
import { apiRequest } from "../../util/util";
import { createItem, updateItem } from "../../util/db";
import { useAuth } from "../../util/auth";
import GenerateButton from "./GenerateButton";

export default function ProductDescriptionGeneratorMainContent(props) {
  const [productName, setProduceName] = useState("");
  const [context, setContext] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionId, setDescriptionId] = useState("");

  const { pastActivityItem } = props;
  useEffect(() => {
    if (pastActivityItem) {
      setProduceName(pastActivityItem.recipient);
      setContext(pastActivityItem.context);
      setDescription(pastActivityItem.content);
      setDescriptionId(pastActivityItem.id);
    }
  }, [pastActivityItem]);

  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const genDescription = await apiRequest("generate-description", "POST", {
      productName,
      context,
      uid: auth.user.uid,
    });
    setDescription(genDescription);
    const ref = await createItem({
      content: genDescription,
      productName,
      context,
      owner: auth.user.uid,
      tool: "description",
    });
    setDescriptionId(ref.id);
  };

  const updateDescription = async (text) => {
    setDescription(text);
    updateItem(descriptionId, {
      content: text,
      recipient: productName,
      context,
      owner: auth.user.uid,
      tool: "description",
    });
  };

  const handleReset = () => {
    setProduceName("");
    setContext("");
    setDescription("");
    setDescriptionId("");
  };

  return (
    <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
      {/* <div className="h-full rounded-lg border-2 border-dashed border-gray-200" />  */}
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Generate Product Description Content
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fill out the following two boxes as best as you can, and we'll
                  take it from there!
                </p>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label
                    htmlFor="product-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Product Name (If it exists)
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    {/* <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                      linkedin.com/
                    </span> */}
                    <input
                      type="text"
                      name="product-name"
                      id="product-name"
                      autoComplete="product-name"
                      className="block w-full min-w-0 flex-grow rounded-l-md rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="ex. Embarc Windbreaker Jacket"
                      onChange={(e) => setProduceName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label
                    htmlFor="context"
                    className="block text-sm font-medium text-gray-700"
                  >
                    What does it do?
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="context"
                      name="context"
                      rows={3}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="High-quality, water-proof windbreaker great for hiking, adult jacket for men, size (Men’s): small - XL, color: black or blue, excellent gift for friends or family"
                      defaultValue={""}
                      onChange={(e) => setContext(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="reset"
                style={{ marginRight: "10px" }}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {description ? "New" : "Clear"}
              </button>
              <GenerateButton disabled={!productName && !context} />
            </div>
          </div>
        </form>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 pb-4">
                Yipper Generated Content
              </h3>
              <div className="col-span-3">
                <div className="mt-1">
                  <textarea
                    disabled={!description}
                    id="description"
                    name="description"
                    rows={14}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Press generate and your product description will appear here"
                    value={description}
                    defaultValue={""}
                    onChange={(e) => updateDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  PencilSquareIcon,
  MagnifyingGlassCircleIcon,
  NewspaperIcon,
  IdentificationIcon,
  SparklesIcon,
  PresentationChartBarIcon,
  ArrowPathIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../util/auth";
import Section from "../Util/Section";
import DashboardSidebar from "./DashboardSidebar";
import EmailGeneratorMainContent from "../GenerationTab/EmailGeneratorMainContent";
import History from "./GeneratorHistory";
import BlogGeneratorMainContent from "../GenerationTab/BlogGeneratorMainContent";
import ProductDescriptionGeneratorMainContent from "../GenerationTab/ProductDescriptionGeneratorMainContent";
import SocialMediaContentGeneratorMainContent from "../GenerationTab/SocialMediaContentGeneratorMainContent";
import GrammarCheckerMainContent from "../GenerationTab/GrammarCheckerMainContent";
import CompanyTaglinesGeneratorMainContent from "../GenerationTab/CompanyTaglinesGeneratorMainContent";
import RewriteParaphraseGeneratorMainContent from "../GenerationTab/RewriteParaphraseGeneratorMainContent";
import ParagraphGeneratorMainContent from "../GenerationTab/ParagraphGeneratorMainContent";
import SettingsBilling from "../Settings/SettingsBilling";
import TokenLimitModal from "./TokenLimitModal";
import { plans } from "../Settings/PricingSection";

const navigation = [
  {
    name: "Email",
    key: "email",
    icon: PencilSquareIcon,
  },
  {
    name: "Blog/Newsletter",
    key: "blog",
    icon: NewspaperIcon,
  },
  {
    name: "Product Description",
    key: "description",
    icon: PresentationChartBarIcon,
  },
  {
    name: "Social Media Content Copy",
    key: "copy",
    icon: IdentificationIcon,
  },
  {
    name: "Grammar",
    key: "grammar",
    icon: MagnifyingGlassCircleIcon,
  },
  {
    name: "Rewrite/Paraphrase",
    key: "paraphrase",
    icon: ArrowPathIcon,
  },
  {
    name: "Company Tagline/Slogan",
    key: "slogan",
    icon: SparklesIcon,
  },
  {
    name: "Paragraph",
    key: "paragraph",
    icon: PencilSquareIcon,
  },
];

const history = {
  name: "History",
  key: "history",
  icon: ClockIcon,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const generationApps = {
  email: (activityItem) => (
    <EmailGeneratorMainContent pastActivityItem={activityItem} />
  ),
  blog: (activityItem) => (
    <BlogGeneratorMainContent pastActivityItem={activityItem} />
  ),
  description: (activityItem) => (
    <ProductDescriptionGeneratorMainContent pastActivityItem={activityItem} />
  ),
  copy: (activityItem) => (
    <SocialMediaContentGeneratorMainContent pastActivityItem={activityItem} />
  ),
  grammar: (activityItem) => (
    <GrammarCheckerMainContent pastActivityItem={activityItem} />
  ),
  paraphrase: (activityItem) => (
    <RewriteParaphraseGeneratorMainContent pastActivityItem={activityItem} />
  ),
  slogan: (activityItem) => (
    <CompanyTaglinesGeneratorMainContent pastActivityItem={activityItem} />
  ),
  paragraph: (activityItem) => (
    <ParagraphGeneratorMainContent pastActivityItem={activityItem} />
  ),
  settings: (activityItem) => (
    <SettingsBilling pastActivityItem={activityItem} />
  ),
};

export default function DashboardMain(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [generationApp, setGenerationApp] = useState("email");
  const [currentActivityItem, setCurrentActivityItem] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(true);
  const auth = useAuth();

  const [limitedReached, setLimitedReached] = useState(false);
  const numTokensUsed = auth.user.num_tokens_used;
  const maxNumTokens = plans.find(
    (plan) => plan.id === auth.user.plan
  ).numWords;
  useEffect(() => {
    if (maxNumTokens === "Unlimited") return;
    if (numTokensUsed >= maxNumTokens) {
      setLimitedReached(true);
    }
  }, [maxNumTokens, numTokensUsed]);

  const onClickHistoryItem = (activityItem) => {
    setCurrentActivityItem(activityItem);
  };

  useEffect(() => {
    if (currentActivityItem) {
      setGenerationApp(currentActivityItem.tool);
    }
  }, [currentActivityItem]);

  const onClickSidebarItem = (key) => {
    setGenerationApp(key);
  };

  return (
    <Section
      bgColor={props.bgColor}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <>
        {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full overflow-hidden">
        ```
      */}
        <TokenLimitModal
          open={limitedReached}
          onDone={() => {
            window.location.href = "https://yipperai1.webflow.io/pricing";
          }}
        />
        <div className="flex h-screen">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transitionf-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              -
              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                      <div className="flex flex-shrink-0 items-center px-4">
                        <a href="/" aria-label="Home">
                          <img
                            className="h-7 w-sm"
                            src={require("../Images/YipperLogo.png")}
                          />
                        </a>
                      </div>
                      <nav aria-label="Sidebar" className="mt-5">
                        <div className="space-y-1 px-2">
                          {navigation.map((item) => (
                            <button
                              key={item.key}
                              onClick={() => {
                                setGenerationApp(item.key);
                              }}
                              className={classNames(
                                item.name === generationApp
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-500"
                                    : "text-gray-400 group-hover:text-gray-500",
                                  "mr-4 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </button>
                          ))}
                        </div>
                      </nav>
                    </div>
                    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                      <a
                        href="/settings/:section"
                        className="group block flex-shrink-0"
                      >
                        <div className="flex items-center">
                          <div>
                            <img
                              className="inline-block h-10 w-10 rounded-full"
                              src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                              alt=""
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                              USERNAME
                            </p>
                            <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                              View profile
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <DashboardSidebar
            items={navigation}
            onClickItem={onClickSidebarItem}
            selectedItem={generationApp}
            setSidePanelOpen={setSidePanelOpen}
            history={history}
          />
          <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
            <div className="lg:hidden">
              <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
                <div>
                  <a href="/" aria-label="Home">
                    <img
                      className="h-7 w-sm"
                      src={require("../Images/YipperLogo.png")}
                    />
                  </a>
                </div>
                <div>
                  <button
                    type="button"
                    className="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                    // TODO doesnt work
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="relative z-0 flex flex-1 overflow-hidden">
              <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                {/* Start main area*/}
                {generationApps[generationApp](currentActivityItem)}
                {/* End main area */}
              </main>
              {/* <aside className="relative hidden w-96 flex-shrink-0 overflow-y-auto border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
                {/* Start secondary column (hidden on smaller screens)
                <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                  <History onClickHistoryItem={onClickHistoryItem} />
                </div>
                {/* End secondary column */}
              {/* </aside>  */}
            </div>
          </div>
        </div>
      </>
    </Section>
  );
}

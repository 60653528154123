import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useAuth } from "../../util/auth";
import SettingsModal from "../Settings/SettingsModal";
import { useState } from "react";
import { useItemsByOwner } from "../../util/db";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SidebarProfile(props) {
  const auth = useAuth();

  const [openingSettings, setOpeningSettings] = useState(false);

  return (
    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
      <Menu as="div" className="relative inline-block px-3 text-left">
        <div>
          <Menu.Button className="group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="flex w-full items-center justify-between ">
              <span className="flex min-w-0 items-center justify-between space-x-3">
                <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-200">
                  <svg
                    className="h-full w-full text-gray-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
                <span className="flex min-w-0 flex-1 flex-col">
                  <span className="truncate text-sm font-medium text-gray-900">
                    Account Details
                  </span>
                  {/* <span className="truncate text-sm text-gray-500">
                    Subheader if we want
                  </span> */}
                </span>
              </span>
              <ChevronUpDownIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="-top-2 transform -translate-y-full absolute right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="javascript:;"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={() => setOpeningSettings(true)}
                  >
                    Settings
                  </a>
                )}
              </Menu.Item>
            </div>
            {/* <div className="py-1"> */}
            {/* <Menu.Item>
                {({ active }) => (
                  <a
                    href="javascript:;"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    // TODO add modal for contacting/emailing us
                  >
                    Support
                  </a>
                )}
              </Menu.Item> */}
            {/* <Menu.Item>
                {({ active }) => (
                  <a
                    href="/legal/:section"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Legal
                  </a>
                )}
              </Menu.Item> */}
            {/* </div> */}
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/auth/signout"
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Logout
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {openingSettings && (
        <SettingsModal onDone={() => setOpeningSettings(false)} />
      )}
    </div>
  );
}

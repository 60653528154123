import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../util/auth";
import TextField from "../Util/TextField";
import Button from "../Util/Button";

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mb-4">
      <TextField
        type="text"
        id="name"
        name="name"
        placeholder="Name"
        label="Name"
        defaultValue={auth.user.name}
        error={errors.name}
        inputRef={register({
          required: "Please enter your name",
        })}
      />

      <TextField
        type="email"
        id="email"
        name="email"
        placeholder="Email"
        label="Email"
        defaultValue={auth.user.email}
        error={errors.email}
        inputRef={register({
          required: "Please enter your email",
        })}
      />

      <Button
        type="submit"
        size="md"
        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        variant="primary"
        disabled={pending}
        isBlock={true}
      >
        {pending ? "..." : "Save"}
      </Button>
    </form>
  );
}

export default SettingsGeneral;

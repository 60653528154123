import React from "react";
import Meta from "../components/Util/Meta";
import DashboardMain from "../components/Dashboard/DashboardMain";
import { requireAuth } from "../util/auth";
function Dashboard(props) {
  return (
    <>
      <Meta title="Generate Content with Yipper" />
      <DashboardMain />
    </>
  );
}

export default requireAuth(Dashboard);

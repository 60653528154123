import React, { useState } from "react";
import ReauthModal from "./ReauthModal";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import SettingsBilling from "./SettingsBilling";
import { useAuth } from "../../util/auth";
import Section from "../Util/Section";

function SettingsSection(props) {
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    general: true,
    password: true,
    billing: true,
  };
  const initialSection = validSections[props.section]
    ? props.section
    : "general";
  const [section, setSection] = useState(initialSection);
  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  return (
    <Section
      bgColor={props.bgColor}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      bgImageRepeat={props.bgImageRepeat}
    >
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}
      <nav class="flex items-center border-b border-gray-200 max-w-md mx-auto mb-12">
        {[
          ["General", "general", section === "general"],
          ["Password", "password", section === "password"],
          ["Billing", "billing", section === "billing"],
        ].map(([name, value, selected]) => (
          <button
            onClick={() => {
              if (value === "billing") {
                window.location.href = "https://yipperai1.webflow.io/pricing";
              } else {
                setSection(value);
              }
            }}
            class={
              "flex items-center grow justify-center px-3 md:px-4 -mb-px py-4 border-b-2" +
              (selected
                ? " text-indigo-500 border-indigo-500"
                : " border-transparent hover:text-indigo-500")
            }
          >
            {name}
          </button>
        ))}
      </nav>

      <div className="container mx-auto mt-5 max-w-screen-sm">
        {formAlert && (
          <div
            className={
              "mb-4" +
              (formAlert.type === "error" ? " text-red-600" : "") +
              (formAlert.type === "success" ? " text-green-600" : "")
            }
          >
            {formAlert.message}
          </div>
        )}

        {section === "general" && <SettingsGeneral onStatus={handleStatus} />}

        {section === "password" && <SettingsPassword onStatus={handleStatus} />}
      </div>
    </Section>
  );
}

export default SettingsSection;

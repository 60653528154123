import { useAuth } from "../../util/auth";
import { useItemsByOwner } from "../../util/db";
import SidebarProfile from "./SidebarProfile";
import { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { plans } from "../Settings/PricingSection";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className="h-1 w-full bg-gray-300">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full ${
          progressPercentage < 70 ? "bg-red-600" : "bg-green-600"
        }`}
      ></div>
    </div>
  );
};

export default function DashboardSidebar(props) {
  const { selectedItem, onClickItem, items, setSidePanelOpen, history } = props;
  const auth = useAuth();
  const { data: allItems, status } = useItemsByOwner(auth.user.uid);
  const [tooltipStatus, setTooltipStatus] = useState(0);
  const [tokensRemaining, setTokensRemaining] = useState(0);

  const numTokensUsed = auth.user.num_tokens_used;
  const maxNumTokens = plans.find(
    (plan) => plan.id === auth.user.plan
  ).numWords;
  useEffect(() => {
    if (maxNumTokens === "Unlimited") {
      setTokensRemaining("Unlimited");
    } else {
      setTokensRemaining(maxNumTokens - numTokensUsed);
    }
  }, [maxNumTokens, numTokensUsed]);

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex w-64 flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-gray-100">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <a href="/" aria-label="Home">
                <img
                  className="h-7 w-sm"
                  src={require("../Images/YipperLogo.png")}
                />
              </a>
            </div>
            <nav className="mt-5 flex-1" aria-label="Sidebar">
              <div className="space-y-1 px-2 pb-4">
                {items.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => onClickItem(item.key)}
                    style={{ width: "-webkit-fill-available" }}
                    className={classNames(
                      item.key === selectedItem
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                ))}
              </div>
              {/* <div className="space-y-1 px-2 border-t">
                <button
                  key={history.name}
                  onClick={setSidePanelOpen(true)}
                  style={{ width: "-webkit-fill-available" }}
                  className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 mt-4 text-sm font-medium rounded-md "
                >
                  <history.icon
                    className="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    aria-hidden="true"
                  />
                  {history.name}
                </button>
              </div> */}
            </nav>
          </div>
          <div class="flex justify-between ml-5 mr-5 align-items-center">
            <div className="mr-2  flex items-center px-2 py-2 mt-6 text-sm font-medium rounded-md flex align-items-center">
              <span class="text-sm items-start font-medium">Words</span>
              <Tippy content={`You have used ${numTokensUsed} words`}>
                <InformationCircleIcon
                  data-tippy-content="Tooltip"
                  className="h-4 w-4 text-color"
                  aria-hidden="true"
                  style={{ marginLeft: "2px" }}
                />
              </Tippy>
              <span class="text-sm ml-8 items-end font-medium text-indigo-600">
                {tokensRemaining === "Unlimited"
                  ? "Unlimited"
                  : `${tokensRemaining} remaining`}
              </span>
            </div>
          </div>
          <div class="m-4 pb-5">
            <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="text-sm bg-indigo-600 h-2.5 rounded-full "
                style={{ width: `${(numTokensUsed / maxNumTokens) * 100}%` }}
              ></div>
            </div>
          </div>
          <SidebarProfile></SidebarProfile>
        </div>
      </div>
    </div>
  );
}

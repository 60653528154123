import { useEffect, useState } from "react";
import { apiRequest } from "../../util/util";
import { createItem, updateItem } from "../../util/db";
import { useAuth } from "../../util/auth";
import GenerateButton from "./GenerateButton";

export default function GrammarCheckerMainContent(props) {
  const [sentence, setSentence] = useState("");
  const [fixedSentence, setFixedSentence] = useState("");
  const [grammarId, setGrammarId] = useState("");

  const auth = useAuth();

  const { pastActivityItem } = props;
  useEffect(() => {
    if (pastActivityItem) {
      setSentence(pastActivityItem.sentence);
      setFixedSentence(pastActivityItem.content);
      setGrammarId(pastActivityItem.id);
    }
  }, [pastActivityItem]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const genFixedSentence = await apiRequest("generate-grammar", "POST", {
      recipient: sentence,
      uid: auth.user.uid,
    });
    setFixedSentence(genFixedSentence);
    const ref = await createItem({
      content: genFixedSentence,
      sentence,
      owner: auth.user.uid,
      tool: "grammar",
    });
    setGrammarId(ref.id);
  };

  const updateFixedSentence = async (text) => {
    setFixedSentence(text);
    updateItem(grammarId, {
      content: text,
      sentence,
      owner: auth.user.uid,
      tool: "grammar",
    });
  };

  const handleReset = () => {
    setSentence("");
    setFixedSentence("");
    setGrammarId("");
  };

  return (
    <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
      {/* <div className="h-full rounded-lg border-2 border-dashed border-gray-200" />  */}
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Let's check your grammar!
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fill out the following box as best as you can, and we'll take
                  it from there!
                </p>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <label
                    htmlFor="sentence"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Content to be checked
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="sentence"
                      name="sentence"
                      rows={10}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="The Navajo language is complex, with a structure and sounds that makes them unintelligible to anyone without extensive exposure to it."
                      defaultValue={""}
                      onChange={(e) => setSentence(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="reset"
                style={{ marginRight: "10px" }}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {fixedSentence ? "New" : "Clear"}
              </button>
              <GenerateButton disabled={!sentence} />
            </div>
          </div>
        </form>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 pb-4">
                Yipper Generated Content
              </h3>
              <div className="col-span-3">
                <div className="mt-1">
                  <textarea
                    disabled={!fixedSentence}
                    id="fixedSentence"
                    name="fixedSentence"
                    rows={11}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="The Navajo language is complex, with a structure and sounds that makes it unintelligible to anyone."
                    value={fixedSentence}
                    defaultValue={""}
                    onChange={(e) => updateFixedSentence(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { apiRequest } from "../../util/util";
import { createItem, updateItem } from "../../util/db";
import { useAuth } from "../../util/auth";
import GenerateButton from "./GenerateButton";

export default function RewriteParaphraseGeneratorMainContent(props) {
  const [context, setContext] = useState("");
  const [instructions, setInstructions] = useState("");
  const [paraphrase, setParaphrase] = useState("");
  const [paraphraseId, setParaphraseId] = useState("");

  const { pastActivityItem } = props;
  useEffect(() => {
    if (pastActivityItem) {
      setInstructions(pastActivityItem.instructions);
      setContext(pastActivityItem.context);
      setParaphrase(pastActivityItem.content);
      setParaphraseId(pastActivityItem.id);
    }
  }, [pastActivityItem]);

  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const genParaphrase = await apiRequest("generate-paraphrase", "POST", {
      instructions,
      context,
      uid: auth.user.uid,
    });
    setParaphrase(genParaphrase);
    const ref = await createItem({
      content: genParaphrase,
      instructions,
      context,
      owner: auth.user.uid,
      tool: "paraphrase",
    });
    setParaphraseId(ref.id);
  };

  const updateParaphrase = async (text) => {
    setParaphrase(text);
    updateItem(paraphraseId, {
      content: text,
      recipient: instructions,
      context,
      owner: auth.user.uid,
      tool: "paraphrase",
    });
  };

  const handleReset = () => {
    setInstructions("");
    setContext("");
    setParaphrase("");
    setParaphraseId("");
  };

  return (
    <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
      {/* <div className="h-full rounded-lg border-2 border-dashed border-gray-200" />  */}
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Rewriter
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Fill out the following two boxes as best as you can, and we'll
                  take it from there!
                </p>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <label
                    htmlFor="context"
                    className="block text-sm font-medium text-gray-700"
                  >
                    What Content do you want Rewritten
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="context"
                      name="context"
                      rows={3}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="The Apollo program, executed by NASA, achieved the historic feat of landing the first humans on the lunar surface."
                      defaultValue={""}
                      onChange={(e) => setContext(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <label
                    htmlFor="instructions"
                    className="block text-sm font-medium text-gray-700"
                  >
                    What should we do to it?
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    {/* <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                      linkedin.com/
                    </span> */}
                    <input
                      type="text"
                      name="instructions"
                      id="instructions"
                      autoComplete="instructions"
                      className="block w-full min-w-0 flex-grow rounded-l-md rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="ex. Increase sentence complexity and length."
                      onChange={(e) => setInstructions(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="reset"
                style={{ marginRight: "10px" }}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {paraphrase ? "New" : "Clear"}
              </button>
              <GenerateButton disabled={!instructions || !context} />
            </div>
          </div>
        </form>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 pb-4">
                Yipper Generated Content
              </h3>
              <div className="col-span-3">
                <div className="mt-1">
                  <textarea
                    disabled={!paraphrase}
                    id="paraphrase"
                    name="paraphrase"
                    rows={14}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="The Apollo program was the third United States human spaceflight program carried out by the National Aeronautics and Space Administration (NASA), which succeeded in landing the first humans on the Moon."
                    value={paraphrase}
                    defaultValue={""}
                    onChange={(e) => updateParaphrase(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
